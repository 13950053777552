<script lang="ts">
import {defineComponent} from 'vue'
import {ChevronDownIcon, PlusIcon} from "@heroicons/vue/20/solid";
import {XMarkIcon} from "@heroicons/vue/24/outline";
import MultiRangeSliderField from "~/components/fields/MultiRangeSlider.vue";
import Select from "~/components/fields/Select.vue";
import type {CategoriesResponse, CategoryResponse, StoresResponse} from "~/types/responses";


export default defineComponent({
  name: "Filters",
  data() {
    return {
      stores: [],
      categories: [],
      resolutions: [
        {label: '7x', value: '7'},
        {label: '8x', value: '8'},
        {label: '9x', value: '9'},
        {label: '14x', value: '14'},
        {label: '16x', value: '16'},
        {label: '18x', value: '18'},
        {label: '24x', value: '24'},
        {label: '32x', value: '32'},
        {label: '48x', value: '48'},
        {label: '64x', value: '64'},
        {label: '128x', value: '128'},
        {label: '256x', value: '256'},
      ],
      mobileFiltersOpen: false,
      price: null,
      filters: {
        store_ids: [],
        excluded_store_ids: [],
        category_ids: [],
        resolutions: [],
        search: null,
        min_price: 0,
        max_price: 300,
        sort_by: 'latest',
        page: 1,
        is_exclusive: null,
      }
    }
  },
  props: {
    modelValue: Object,
    meta: Object,
    showCategories: Boolean,
    showStores: Boolean,
  },
  components: {
    Select,
    MultiRangeSliderField,
    PlusIcon,
    XMarkIcon,
    ChevronDownIcon,
  },
  mounted() {
    $api('/shop/stores?disable_pagination=true').then((response: StoresResponse) => {
      console.log(response.data)
      this.stores = response.data
    }),
    $api('/shop/categories?disable_pagination=true').then((response: CategoriesResponse) => {
      console.log(response.data)
      this.categories = response.data
    })
    if (this.modelValue) {
      this.filters = this.modelValue
    }
  },
  methods: {
    changePage(page) {
      this.filters.page = page
    }
  },
  computed: {
    filterString() {
      const { page, ...otherFilters } = this.filters;
      return JSON.stringify(otherFilters);
    },
  },
  watch: {
    filterString(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filters.page = 1;
      }
    },
    price: {
      handler(newValue) {
        this.filters.min_price = newValue.min
        this.filters.max_price = newValue.max
      },
      deep: true
    },
    filters: {
      handler(newValue) {
        console.log(newValue)
        this.$emit('update:modelValue', newValue)
      },
      deep: true
    }
  },
})
</script>

<template>
  <div>
    <!-- Mobile filter dialog -->
    <HeadlessTransitionRoot as="template" :show="mobileFiltersOpen">
      <HeadlessDialog as="div" class="relative z-[60] lg:hidden" @close="mobileFiltersOpen = false">
        <HeadlessTransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                                 enter-from="opacity-0" enter-to="opacity-100"
                                 leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
                                 leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-25"/>
        </HeadlessTransitionChild>

        <div class="fixed inset-0 z-[60] flex">
          <HeadlessTransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                   enter-from="translate-x-full" enter-to="translate-x-0"
                                   leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                                   leave-to="translate-x-full">
            <HeadlessDialogPanel
                class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white dark:bg-zinc-800 py-4 pb-6 shadow-xl">
              <div class="flex items-center justify-between px-4">
                <h2 class="text-lg font-medium text-gray-900 dark:text-white">Filters</h2>
                <button type="button"
                        class="relative -mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                        @click="mobileFiltersOpen = false">
                  <span class="absolute -inset-0.5"/>
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
                </button>
              </div>

              <!-- Filters -->
              <form class="px-4">
                <!-- Search -->
                <FieldsInput label="Search by name" name="search" v-model="filters.search"/>
                <FieldsMultiSelect
                    v-if="showCategories"
                    v-model="filters.category_ids"
                    name="categories"
                    label="In category"
                    label-field="name"
                    value-field="id"
                    :records="categories"/>
                <FieldsMultiSelect
                    v-if="showStores"
                    v-model="filters.store_ids"
                    name="stores"
                    label="In store"
                    label-field="name"
                    value-field="id"
                    :records="stores"/>
                <FieldsMultiSelect
                    v-if="showStores"
                    v-model="filters.excluded_store_ids"
                    name="excluded_stores"
                    label="Not in store"
                    label-field="name"
                    value-field="id"
                    :records="stores"/>
                <FieldsCheckBox
                    name="is_exclusive"
                    label="Is exclusive"
                    v-model="filters.is_exclusive"/>
                <MultiRangeSliderField
                    name="price"
                    label="Price range"
                    :min="0"
                    :max="300"
                    :step="1"
                    v-model="price"/>
                <FieldsMultiSelect
                    v-model="filters.resolutions"
                    name="resolutions"
                    label="Resolutions"
                    label-field="label"
                    value-field="value"
                    :records="resolutions"/>
              </form>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </HeadlessDialog>
    </HeadlessTransitionRoot>


    <div class="pb-24 pt-6 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
      <aside>
        <div class="flex justify-between">
          <div class="hidden lg:block">
            <h2 class="text-xl font-semibold tracking-tight text-gray-800 dark:text-gray-100 ">Filters</h2>
          </div>
          <div class="lg:hidden flex" @click="mobileFiltersOpen = !mobileFiltersOpen">
            <h2 class="text-xl font-semibold tracking-tight text-gray-800 dark:text-gray-100 ">Filters</h2>
            <PlusIcon class="ml-1 h-5 w-5 flex-shrink-0 text-gray-400 mt-1.5"/>
          </div>

          <!--          <span-->
          <!--              class="inline-flex items-center gap-x-0.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">-->
          <!--              5 Filters-->
          <!--            <button type="button" class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20">-->
          <!--                <span class="sr-only">Remove</span>-->
          <!--                <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-red-700/50 group-hover:stroke-red-700/75">-->
          <!--                  <path d="M4 4l6 6m0-6l-6 6"/>-->
          <!--                </svg>-->
          <!--                <span class="absolute -inset-1"/>-->
          <!--            </button>-->
          <!--          </span>-->
        </div>
        <Card class="mt-2 hidden lg:block">
          <FieldsInput label="Search by name" name="search" v-model="filters.search"/>
          <FieldsMultiSelect
              v-model="filters.resolutions"
              name="resolutions"
              label="Resolutions"
              label-field="label"
              value-field="value"
              :records="resolutions"/>
          <FieldsMultiSelect
              v-if="showCategories"
              v-model="filters.category_ids"
              name="categories"
              label="In category"
              label-field="name"
              value-field="id"
              :records="categories"/>
          <FieldsMultiSelect
              v-if="showStores"
              v-model="filters.store_ids"
              name="stores"
              label="In store"
              label-field="name"
              value-field="id"
              :records="stores"/>
          <FieldsMultiSelect
              v-if="showStores"
              v-model="filters.excluded_store_ids"
              name="excluded_stores"
              label="Not in store"
              label-field="name"
              value-field="id"
              :records="stores"/>
          <FieldsCheckBox
              name="is_exclusive"
              label="Is exclusive"
              v-model="filters.is_exclusive"/>
          <MultiRangeSliderField
              name="price"
              label="Price range"
              :min="0"
              :max="300"
              :step="1"
              v-model="price"/>
        </Card>
      </aside>

      <section class="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
        <div class="flex justify-between">
          <div class="flex gap-2">
            <h2 class="text-xl font-semibold tracking-tight text-gray-800 dark:text-gray-100">Sort by</h2>
            <ProductSortButton v-model="filters.sort_by"/>
          </div>
          <div class="flex justify-end mb-2">
            <Pagination :data="meta" @changePage="changePage"/>
          </div>
        </div>
        <slot/>
        <div class="flex justify-end mt-4">
          <Pagination :data="meta" @changePage="changePage"/>
        </div>
      </section>
    </div>

  </div>
</template>

<style scoped>
.slide-enter, .slide-leave-to {
  transform: scaleY(0);
}
</style>

